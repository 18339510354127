var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('v-card',[_c('v-card-title',[(!!_vm.fact_details.fact_data)?_c('span',{staticClass:"headline"},[_vm._v(" Factura "+_vm._s(_vm.fact_details.fact_data.serie_doc)+" ")]):_vm._e(),_c('v-spacer'),(
            !!_vm.fact_details.fact_data && _vm.fact_details.fact_data.remission_file
          )?_c('a',{attrs:{"href":("" + (_vm.fact_details.fact_data.remission_file)),"target":"_blank"}},[_vm._v("GUIA DE REMISION")]):_vm._e()],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',[(_vm.fact_details)?_c('doc-show',{attrs:{"fact_data":_vm.fact_details.fact_data,"products":_vm.fact_details.products}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.detailDialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.sendDialog),callback:function ($$v) {_vm.sendDialog=$$v},expression:"sendDialog"}},[_c('send-sunat',{attrs:{"fact_details":_vm.fact_details,"show":_vm.sendDialog},on:{"finishSend":_vm.sendSunat,"cancel":function($event){_vm.sendDialog = false}}})],1),_c('v-container',{staticClass:"my-6 px-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-md-h3"},[_vm._v("Ver Documentos")])])],1),_c('v-row',[(!!_vm.transactions)?_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.transactions,"item-key":"id","search":_vm.search,"items-per-page":10,"loading":_vm.transactions_loading,"sort-by":"created_at","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.sell_value",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateIGV(item.total_ammount).sell_value)+" ")]}},{key:"item.igv_value",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateIGV(item.total_ammount).igv_value)+" ")]}},{key:"item.state",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:item.state === 'DESPACHADO' ? 'success--text' : 'warning-text'},[_vm._v(_vm._s(item.state))])]}},{key:"item.payment_state",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:item.payment_state === 'CANCELADO'
                  ? 'success--text'
                  : 'warning--text'},[_vm._v(_vm._s(item.payment_state))])]}},{key:"item.sunat_state",fn:function(ref){
                  var item = ref.item;
return [(item.conflux_id)?_c('a',{class:item.sunat_state === 'ENVIADO'
                  ? 'success--text'
                  : 'warning--text',attrs:{"href":("https://einvoice.conflux.pe/cpe/" + (item.conflux_id) + ".pdf"),"target":"_blank"}},[_vm._v(_vm._s(item.sunat_state))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.seeDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Detalles")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":"","disabled":item.state === 'DESPACHADO'},on:{"click":function($event){return _vm.dispatch(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-car ")])]}}],null,true)},[_c('span',[_vm._v("Despachar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":"","disabled":item.sunat_state === 'ENVIADO'},on:{"click":function($event){return _vm.openSendDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-send ")])]}}],null,true)},[_c('span',[_vm._v("Enviar a SUNAT")])])]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at))+" ")]}}],null,false,946394753)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }