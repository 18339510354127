<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="!!fact_details">
        {{ fact_details.type_doc }} {{ fact_details.serie }} - Guía de Remisión
      </span>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-container>
        <v-switch
          v-model="useRemission"
          label="Agregar guía de remisión"
        ></v-switch>
        <v-switch
          v-model="useAuxAddress"
          label="Usar otra dirección para la guia deremission"
        >
        </v-switch>
        <v-form
          ref="remissionForm"
          v-model="remissionFormModel"
          :disabled="!useAuxAddress"
        >
          <v-row class="mt-1" justify="center">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="remission.aux_address"
                label="Nueva Direccion, ponga entre parentesis el ubigeo"
                outlined
                :rules="[v => !!v || 'Requerido']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color=" darken-1" text @click="$emit('cancel')">
        Cancelar
      </v-btn>
      <v-btn color="blue darken-1" text @click="finishSend">
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['fact_details', 'show'],
  data: () => ({
    remission: {},
    useRemission: false,
    useAuxAddress: false,
    remissionFormModel: false
  }),

  watch: {
    show(value) {
      if (value === true) {
        this.useRemission = false
        this.useAuxAddress = false
        this.remission.aux_address = ''
        console.log('Show value:', this.remission)
      } else {
        console.log('Se cierra')
      }
    }
  },
  methods: {
    finishSend() {
      if (this.useRemission === false) {
        this.$emit('finishSend', {
          useRemission: false,
          id: this.fact_details.id
        })
        return
      }
      if (this.useRemission === true) {
        this.$emit('finishSend', {
          useRemission: this.useRemission,
          useAuxAddress: this.useAuxAddress,
          remission: this.remission,
          id: this.fact_details.id
        })
      }
    }
  }
}
</script>
