<template>
  <div v-if="!!fact_data">
    <!-- <v-row class="mb-1" justify="center" align="center">
      <v-col>
        <v-text-field
          :value="fact_data.state"
          label="Estado"
          readonly
          outlined
          hide-details="true"
          dense
        />
      </v-col>
      <v-col>
        <v-btn color="green lighten-2" @click="dispatch" dense>Despachar</v-btn>
      </v-col>
      <v-col
        ><v-text-field
          :value="fact_data.id"
          label="ENVIADO"
          readonly
          outlined
          hide-details="true"
          dense
        />
      </v-col>
      <v-col>
        <v-btn color="secondary" @click="sendSunat" dense>Enviar a SUNAT</v-btn>
      </v-col>
    </v-row> -->

    <v-row class="mt-1" justify="center">
      <v-col cols="6" md="3">
        <v-text-field
          :value="fact_data.tipo_doc"
          label="Tipo de documento"
          readonly
          outlined
          hide-details="true"
          dense
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          :value="fact_data.serie_doc"
          placeholder="Serie del documento"
          label="Serie"
          readonly
          outlined
          hide-details="true"
          dense
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Documento del cliente"
          v-model="fact_data.client_doc"
          ref="client_doc"
          outlined
          readonly
          hide-details="true"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="fact_data.client_name"
          label="Cliente"
          outlined
          readonly
          dense
          hide-details="true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Dirección del cliente"
          v-model="fact_data.client_direccion"
          outlined
          readonly
          hide-details="true"
          dense
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          label="Departamento"
          v-model="fact_data.departamento"
          outlined
          hide-details="true"
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Provincia"
          v-model="fact_data.provincia"
          outlined
          dense
          hide-details="true"
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Distrito"
          v-model="fact_data.distrito"
          outlined
          hide-details="true"
          readonly
          dense
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="products"
          item-key="id"
          class="elevation-1"
          ref="productsTable"
          :hide-default-footer="true"
          disable-pagination
          sort-by="code"
          no-data-text="No se seleccionaron productos"
          dense
        >
          <template v-slot:item.is_free="{ item }">
            <v-simple-checkbox
              color="primary"
              v-model="item.is_free"
              :ripple="false"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.code="{ item }">
            <span
              :class="
                item.stock < item.quantity ? 'error--text' : 'success--text'
              "
            >
              {{ item.code }}</span
            >
          </template>

          <template v-slot:item.offset="{ item }">
            <span
              :class="
                item.stock < item.quantity ? 'error--text' : 'success--text'
              "
            >
              {{
                0 > item.stock - item.quantity
                  ? Math.abs(item.stock - item.quantity)
                  : 0
              }}
            </span>
          </template>
          <template v-slot:item.total_price="{ item }">
            {{
              getTotalPrice(
                item.unit_price,
                item.quantity,
                item.discount,
                item.is_free
              )
            }}
          </template>
          <template v-slot:no-data>
            <span class="error--text text--lighten-2">
              No se seleccionaron productos
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="6" md="2" align="right" class="text-h6">
        <v-text-field
          label="Valor de Venta"
          :prefix="fact_data.currency === 'SOLES' ? 'S/.' : '$'"
          :value="getSellValue(fact_data.total_ammount)"
          hide-details="true"
          text-align="end"
          outlined
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" sm="6" md="2" align="right" class="text-h6">
        <v-text-field
          label="IGV"
          :prefix="fact_data.currency === 'SOLES' ? 'S/.' : '$'"
          :value="getIgvValue(fact_data.total_ammount)"
          hide-details="true"
          outlined
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" md="2" align="right" class="text-h6">
        <v-text-field
          label="Total"
          :prefix="fact_data.currency === 'SOLES' ? 'S/.' : '$'"
          v-model="fact_data.total_ammount"
          hide-details="true"
          outlined
          readonly
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: { fact_data: Object, products: Array },
  data: () => ({
    headers: [
      {
        text: 'Gratuito',
        align: 'center',
        value: 'is_free',
        divider: true
      },
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'code',
        divider: true
      },
      {
        text: 'Descripción',
        value: 'description',
        divider: true,
        filtereable: false
      },
      {
        text: 'Stock',
        sortable: false,
        value: 'stock',
        divider: true,
        align: 'center'
      },
      {
        text: 'Cantidad',
        sortable: false,
        value: 'quantity',
        divider: true,
        align: 'center'
      },
      {
        text: 'Faltan',
        sortable: false,
        value: 'offset',
        divider: true,
        align: 'center'
      },
      {
        text: 'Precio Unitario',
        filtereable: false,
        sortable: false,
        value: 'unit_price',
        divider: true,
        align: 'right'
      },
      {
        text: 'Descuento (%)',
        filtereable: false,
        sortable: false,
        divider: true,
        value: 'discount',
        align: 'center'
      },
      {
        text: 'Precio Total',
        filtereable: false,
        sortable: false,
        value: 'total_price',
        divider: true,
        align: 'right'
      }
    ]
  }),
  methods: {}
}
</script>

<style lang="css" scoped>
.v-input >>> .v-label {
  /* font-size: 1em; */
  text-transform: uppercase;
}
</style>
