<template>
  <div class="home">
    <v-dialog v-model="detailDialog" max-width="80%">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!!fact_details.fact_data">
            Factura {{ fact_details.fact_data.serie_doc }}
          </span>
          <v-spacer></v-spacer>
          <a
            :href="`${fact_details.fact_data.remission_file}`"
            class=""
            target="_blank"
            v-if="
              !!fact_details.fact_data && fact_details.fact_data.remission_file
            "
            >GUIA DE REMISION</a
          >
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <doc-show
              v-if="fact_details"
              :fact_data="fact_details.fact_data"
              :products="fact_details.products"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="detailDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendDialog" max-width="80%">
      <send-sunat
        v-on:finishSend="sendSunat"
        v-on:cancel="sendDialog = false"
        :fact_details="fact_details"
        :show="sendDialog"
      />
    </v-dialog>

    <v-container class="my-6 px-10" fluid>
      <v-row>
        <v-col>
          <div class="text-h5 text-md-h3">Ver Documentos</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!!transactions">
          <v-data-table
            :headers="headers"
            :items="transactions"
            item-key="id"
            class="elevation-1"
            :search="search"
            :items-per-page="10"
            :loading="transactions_loading"
            sort-by="created_at"
            :sort-desc="true"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:item.sell_value="{ item }">
              {{ calculateIGV(item.total_ammount).sell_value }}
            </template>
            <template v-slot:item.igv_value="{ item }">
              {{ calculateIGV(item.total_ammount).igv_value }}
            </template>
            <template v-slot:item.state="{ item }">
              <span
                :class="
                  item.state === 'DESPACHADO' ? 'success--text' : 'warning-text'
                "
                >{{ item.state }}</span
              >
            </template>
            <template v-slot:item.payment_state="{ item }">
              <span
                :class="
                  item.payment_state === 'CANCELADO'
                    ? 'success--text'
                    : 'warning--text'
                "
                >{{ item.payment_state }}</span
              >
            </template>
            <template v-slot:item.sunat_state="{ item }">
              <a
                :href="`https://einvoice.conflux.pe/cpe/${item.conflux_id}.pdf`"
                target="_blank"
                :class="
                  item.sunat_state === 'ENVIADO'
                    ? 'success--text'
                    : 'warning--text'
                "
                v-if="item.conflux_id"
                >{{ item.sunat_state }}</a
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2"
                    @click="seeDetails(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Detalles</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2"
                    @click="dispatch(item)"
                    :disabled="item.state === 'DESPACHADO'"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-car
                  </v-icon>
                </template>
                <span>Despachar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2"
                    @click="openSendDialog(item)"
                    :disabled="item.sunat_state === 'ENVIADO'"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-send
                  </v-icon>
                </template>
                <span>Enviar a SUNAT</span>
              </v-tooltip>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ $formatDate(item.created_at) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DocShow from '../components/facturation/DocShow.vue'
import SendSunat from '../components/facturation/sendSunat.vue'

export default {
  components: { DocShow, SendSunat },
  data: () => ({
    transactions: [],
    transactions_loading: true,
    search: '',
    headers: [
      {
        text: 'Tipo',
        value: 'type_doc',
        sortable: false,
        filtereable: false,
        groupable: true
      },
      {
        text: 'N°',
        value: 'serie',
        filtereable: true,
        groupable: false,
        sortable: false
      },
      {
        text: 'Doc. Cliente',
        value: 'client_doc',
        filtereable: true
      },
      {
        text: 'Nombre cliente',
        value: 'client_name',
        groupable: false,
        filtereable: true,
        sortable: false,
        width: '25%'
      },
      {
        text: 'Vendedor',
        value: 'seller',
        filtereable: true,
        align: 'start'
      },
      {
        text: 'Fecha de emisión',
        value: 'created_at',
        filtereable: true,
        align: 'center'
      },
      // {
      //   text: 'Valor de venta',
      //   groupable: false,
      //   value: 'sell_value',
      //   sortable: false,
      //   align: 'right'
      // },
      // {
      //   text: 'IGV',
      //   groupable: false,
      //   sortable: false,
      //   value: 'igv_value',
      //   align: 'right'
      // },
      {
        text: 'Precio Total',
        groupable: false,
        sortable: false,
        value: 'total_ammount',
        align: 'right'
      },
      {
        text: 'Estado',
        groupable: false,
        value: 'state',
        align: 'center',
        sortable: false
      },
      {
        text: 'Pago',
        groupable: false,
        value: 'payment_state',
        align: 'center',
        sortable: false
      },
      {
        text: 'Sunat',
        groupable: true,
        value: 'sunat_state',
        align: 'center',
        sortable: false
      },
      {
        text: 'Detalles',
        value: 'actions',
        sortable: false,
        filtereable: false,
        align: 'center'
      }
    ],
    fact_details: '',
    detailDialog: false,
    sendDialog: false
  }),
  mounted: function() {
    this.$makeGetRequest('/api/transactions').then(response => {
      this.transactions = response.data.data
      this.transactions_loading = false
    })
  },
  methods: {
    seeDetails(item) {
      this.$makeGetRequest(`/api/transactions/${item.id}/get`).then(
        response => {
          this.fact_details = response.data.data
          this.detailDialog = true
        }
      )
    },
    async dispatch(item) {
      console.log('Change status to DESPACHADO', item)
      try {
        let res = await this.$makePostRequest(
          `/api/transactions/${item.id}/dispatch`
        )
        console.log(res)
        this.$showMessage('Estado cambiado a DESPACHADO', 'success')

        this.transactions_loading = true
        this.$makeGetRequest('/api/transactions').then(response => {
          this.transactions = response.data.data
          this.transactions_loading = false
        })
      } catch (error) {
        console.log(error)
        this.$showMessage('Hubo un error al cambiar el estado', 'error')
      }
    },
    openSendDialog(item) {
      this.sendDialog = true
      this.fact_details = item
      // this.$showMessage(`${item.type_doc} ${item.serie} con éxito`, 'success')
      console.log('Send to SUNAT', item)
    },
    calculateIGV(ammount) {
      let sell_value = (ammount / 1.18).toFixed(2)
      let igv_value = (ammount - sell_value).toFixed(2)
      return { sell_value, igv_value }
    },
    async sendSunat(remissionData) {
      this.sendDialog = false

      console.log('Ready to send:', remissionData)
      if (remissionData.useRemission) {
        try {
          await this.$makePostRequest(
            `/api/transactions/${remissionData.id}/set-remission`,
            remissionData
          )
        } catch (error) {
          console.log(error)
          this.$showMessage(
            'Hubo un error al guardar la guia de remission',
            'error'
          )
          return
        }
      }

      try {
        let response = await this.$makeGetRequest(
          `/api/transactions/${remissionData.id}/send`
        )
        response = response.data
        if (response.error) {
          this.$showMessage(`Conflux: ${response.message}`, 'error', 5)
        }
        if (response.status === 'success') {
          this.$showMessage(`Conflux: ${response.message}`, 'success', 5)
        }
      } catch (error) {
        console.log(error)
        this.$showMessage(
          'Hubo un error al validar los datos con la sunat',
          'error'
        )
      }

      try {
        await this.$makeGetRequest('/api/transactions').then(response => {
          this.transactions = response.data.data
          this.transactions_loading = false
        })
      } catch {
        this.$showMessage(
          'Hubo un error al actualizar las transacciones, actualice la página porfavor',
          'error'
        )
      }
    }
  }
}
</script>
